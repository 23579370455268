body
    background: #030303

.toolbar
    display: flex
    padding: 20px 0
    align-items: center
    border-top: 2px solid #ccc
    border-bottom: 2px solid #ccc
    padding-left: 15px
    color: #fff


.toolbar-controls
    margin-right: 30px


.toolbar-form
    display: flex

.toolbar-form > div
    margin-right: 20px

.wrapper
    display: flex
    flex-wrap: wrap

.sequence
  padding-left: 10px
  .active
    color: #cc2c11

.layer
    display: flex
    align-items: center
    flex-direction: column
    color: #fff
    margin-right: 55px

.guia
  position: relative
  display: flex
  justify-content: center
  align-items: center
  .tempo
    position: absolute

    text-align: center

    .tempoInput
      font-size: 25px
      text-align: center
      background: none
      border: none
      color: #fff
      display: block
      &:focus
        outline: none
    p
     margin: 0
     font-size: 14px

.volume
    display: flex
    align-items: center


.about
  p,h3
    color: #fff
  p
    font-size: 20px

@media (max-width: 799px)
    .toolbar, .toolbar-form
        flex-direction: column
